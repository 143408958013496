import './about.css'

const About = ({ innerRef }) => {
  return (
    <section id='about' className="about__container" ref={innerRef}>
      <div className="about__textbox">
        <h1>Overview</h1>
        <p>
          Descendants of Earth is a multiplayer environmental mobile game driven by real climate action.  Actions earn resources that are used to build a community, regenerate the planet, and change Earth’s timeline, both in and outside the game.
        </p>
      </div>
    </section>
  )
}

export default About