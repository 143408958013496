import './backstop.css'

const Backstop = () => {
  
  return (
    <section className='backstop__container'>
      <div className="backstop__text">
        <h1>COMING SOON</h1>
      </div>
      <div className="backstop__copyright">
        <small>
            &copy; Descendants Of Earth. All rights reserved.
        </small>
      </div>
    </section>
  )
}

export default Backstop