import './divider.css'

function Divider() {
  return (
    <div className='divider__container'>
        
    </div>
  )
}

export default Divider