import Action from '../../assets/Garden.jpg';
import Community from '../../assets/Flyover2.jpg';
import Expeditions from '../../assets/Wilderness.jpg';

const data = [
    {
      feature: "Rewards for Real Action",
      description: "Use our Action Index to take real environmental action and log it with your mobile device. Taking action changes the timeline and sends resources to the future for your descendants to use.",
      image: Action
    },
    {
      feature: "Community Building",
      description: "Help your descendants face fierce elements by building a resilient community with the resources you send them and maintain their mind, heart and gut vitals.",
      image: Community
    },
    {
      feature: "Launch Expeditions",
      description: "Send your descendants on Expeditions to regenerate the Earth and create a garden utopia.",
      image: Expeditions
    }
  ]

  export default data;