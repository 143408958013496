import './devmap.css'
import {BsHourglassSplit, BsFillCheckCircleFill} from 'react-icons/bs'
import {BiLoader} from 'react-icons/bi'

const DevMap = ({ innerRef }) => {
  return (
    <section id='development' className='devmap__container' ref={innerRef}>
      <div className="devmap__content">
        <h2>Development Timeline</h2>
        <div className="devmap__legend">
          <ul>
            <li>
              <h4><BsFillCheckCircleFill className='devmap__legend-icon'/> Online</h4>
            </li>
            <li>
              <h4><BsHourglassSplit className='devmap__legend-icon'/> In Progress </h4>
            </li>
            <li>
              <h4><BiLoader className='devmap__legend-icon'/> Planned</h4>
            </li>
          </ul>
        </div>
        <ul className="devmap__list">
            <li>
              <BsFillCheckCircleFill className='devmap__list-icon'/>
              <p>Environmental Action Index</p>
            </li>
            <li>
              <BsFillCheckCircleFill className='devmap__list-icon'/>
              <p>Resource Management System</p>
            </li>
            <li>
              <BsFillCheckCircleFill className='devmap__list-icon'/>
              <p>Player Profiles</p>
            </li>
            <li>
              <BsHourglassSplit className='devmap__list-icon'/>
              <p>Community Construction</p>
            </li>
            <li>
              <BsHourglassSplit className='devmap__list-icon'/>
              <p>Expeditions</p>
            </li>
            <li>
              <BiLoader className='devmap__list-icon'/>
              <p>Action Verification</p>
            </li>
            <li>
              <BiLoader className='devmap__list-icon'/>
              <p>Leaderboards</p>
            </li>
          </ul>
      </div>
    </section>
  )
}

export default DevMap