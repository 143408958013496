import { useInView } from 'react-intersection-observer';
import { useState, useEffect } from 'react';
import Header from './components/header/Header'
import Nav from './components/nav/Nav'
import Divider from './components/divider/Divider';
import About from './components/about/About'
import Features from './components/features/Features'
import DevMap from './components/devmap/DevMap'
import DevLog from './components/devlog/DevLog'
import Signup from './components/signup/Signup'
import Footer from './components/footer/Footer'
import Backstop from './components/backstop/Backstop'

function App() {
  const { ref, inView } = useInView();

  const { ref: ref1, inView: inView1 } = useInView();

  const { ref: ref2, inView: inView2 } = useInView();

  const { ref: ref3, inView: inView3 } = useInView();

  const { ref: ref4, inView: inView4 } = useInView();

  const { ref: ref5, inView: inView5 } = useInView();

  const [activeElement, setActiveElement] = useState('#')

  useEffect(() => {
    if (inView) {
      setActiveElement('#home');
    };
    if (inView1) {
      setActiveElement('#about');
    };
    if (inView2) {
      setActiveElement('#features')
    };
    if (inView3) {
      setActiveElement('#development')
    };
    if (inView4) {
      setActiveElement('#play')
    };
    if (inView5) {
      setActiveElement('#contact')
    };
  }, [inView, inView1, inView2, inView3, inView4, inView5])
  
  return (
    <div className="App">
      <Nav activeElement={activeElement}/>
      <Header innerRef={ref} />
      <Divider />
      <About innerRef={ref1} />
      <Divider />
      <Features innerRef={ref2} />
      <Divider />
      <DevMap innerRef={ref3} />
      <Divider />
      <DevLog innerRef={ref3} />
      <Divider />
      <Signup innerRef={ref4}/>
      <Divider />
      <Footer innerRef={ref5} />
      <Divider />
      <Backstop />
    </div>
  );
}

export default App;