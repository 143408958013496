import React, { useState, useEffect } from 'react';
import FeaturesSliderBase from './FeaturesSliderBase';
import data from './FeaturesSliderContent'
import Arrows from './FeaturesArrows';
import Dots from './FeaturesDots';
import './features.css';


const length = data.length - 1;

const Features = ({ innerRef }, props) => {
  const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
        setActiveIndex(activeIndex === length ? 0 : activeIndex + 1);
        }, 15000);
        return () => clearInterval(interval);
    }, [activeIndex]);
  
  return (
    <section id='features' ref={innerRef}>
      <div className="features__body">
        <div className="features__heading">
          <h2>Features</h2>
        </div>
        <div className="features__content">
          <FeaturesSliderBase activeIndex={activeIndex} data={data} />
          <Arrows 
              prevSlide={() => 
                  setActiveIndex(activeIndex < 1 ? length : activeIndex - 1 )
              }
              nextSlide={() => 
                  setActiveIndex(activeIndex === length ? 0 : activeIndex + 1 )
              } 
          />
          <Dots 
              activeIndex={activeIndex} 
              data={data}
              onclick={(activeIndex) => setActiveIndex(activeIndex)}
          />
        </div>
      </div>
    </section>
  )
}

export default Features