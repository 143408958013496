import React from 'react'

function FeaturesDots({ activeIndex, onclick, data}) {
    return (
        <div className="devlog-all-dots">
            {data.map((slide, index) => (
                <span
                key={index}
                className={`${activeIndex === index ? "devlog-dot devlog-active-dot" : "devlog-dot"}`}
                onClick={() => onclick(index)}
                ></span>
            ))}
        </div>
    )
}

export default FeaturesDots;