import React from 'react'
import './nav.css'
import { useState, useEffect } from 'react'
import {FaHome, FaQuestion, FaCog, FaFlag, FaPlay} from 'react-icons/fa'
import {BsPeopleFill} from 'react-icons/bs'

const Nav = ({activeElement}) => {
  const [activeNav, setActiveNav] = useState('#home');
  
  useEffect(() => {
    setActiveNav(activeElement);
  }, [activeElement])

  return (
    <nav>
      <a href="#home" onClick={() => setActiveNav('#home')} className={activeNav === '#home' ? 'active' : ''}><FaHome /></a>
      <a href="#about" onClick={() => setActiveNav('#about')} className={activeNav === '#about' ? 'active' : ''}><FaQuestion /></a>
      <a href="#features" onClick={() => setActiveNav('#features')} className={activeNav === '#features' ? 'active' : ''}><FaCog /></a>
      <a href="#development" onClick={() => setActiveNav('#development')} className={activeNav === '#development' ? 'active' : ''}><FaFlag /></a>
      <a href="#play" onClick={() => setActiveNav('#play')} className={activeNav === '#play' ? 'active' : ''}><FaPlay /></a>
      <a href="#contact" onClick={() => setActiveNav('#contact')} className={activeNav === '#contact' ? 'active' : ''}><BsPeopleFill /></a>
    </nav>
  )
}

export default Nav