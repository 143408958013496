import React, { useState, useEffect } from 'react';
import DevLogSliderBase from './DevLogSliderBase';
import data from './DevLogSliderContent'
import Arrows from './DevLogArrows';
import Dots from './DevLogDots';
import './devlog.css'

const length = data.length - 1;

const DevLog = ({ innerRef }) => {
  const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
        setActiveIndex(activeIndex === length ? 0 : activeIndex + 1);
        }, 120000);
        return () => clearInterval(interval);
    }, [activeIndex]);
  
  return (
    <section id='development' ref={innerRef}>
      <div className="devlog__heading">
        <h2>Dev News</h2>
      </div>
      <div className="devlog__content">
        <DevLogSliderBase activeIndex={activeIndex} data={data} /> 
        <Dots 
            activeIndex={activeIndex} 
            data={data}
            onclick={(activeIndex) => setActiveIndex(activeIndex)}
        />
      </div>
      <Arrows 
            prevSlide={() => 
                setActiveIndex(activeIndex < 1 ? length : activeIndex - 1 )
            }
            nextSlide={() => 
                setActiveIndex(activeIndex === length ? 0 : activeIndex + 1 )
            } 
        />
    </section>
  )
}

export default DevLog