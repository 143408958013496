import './signup.css'

function Signup({ innerRef }) {
  return (
    <section id='play' className='signup__container' ref={innerRef}>
      <div class="ml-form-embed"
        data-account="2999389:t0d2h5x4r6"
        data-form="5752083:n0o1l8">
      </div>
    </section>
  )
}

export default Signup