import './footer.css'
import {BsDiscord} from 'react-icons/bs'
import {SiMinds, SiTiktok, SiFacebook} from 'react-icons/si'
import {AiFillInstagram, AiOutlineTwitter} from 'react-icons/ai'
import VicScreen from '../../assets/VicScreenWhite.png'


const Footer = ({ innerRef }) => {
  return (
    <section id='contact' ref={innerRef}>
      <div className="footer__acknowledgement">
        <h4>
          Descendants of Earth is made in Australia on the land of the Taungurong, Dja Dja Wurrung, Boonwurung and Noongar people.
        </h4>
      </div>
      <hr width="50%" 
        size="5" 
        align="center" 
        color="#EAEAD0" noshade></hr>
      <div className="footer__socials">
        <p className="socials__label">
          Follow DoE at:
        </p>
        <div className="socials__icons">      
          <a href="https://twitter.com/descendantearth" target="_blank" rel="noreferrer noopener" id='twitter'><AiOutlineTwitter /></a>
          <a href="https://www.instagram.com/descendantsofearth/" target="_blank" rel="noreferrer noopener" id='insta'><AiFillInstagram /></a>
          <a href="https://www.facebook.com/descendantsofearth" target="_blank" rel="noreferrer noopener" id="facebook"><SiFacebook /></a>
          <a href="https://www.tiktok.com/@doe.games" target="_blank" rel="noreferrer noopener" id="tiktok"><SiTiktok /></a>
          <a href="https://www.minds.com/descendants_of_earth/" target="_blank" rel="noreferrer noopener" id="minds"><SiMinds /></a>
          <a href="https://discord.gg/zJnZY5r3CJ" target="_blank" rel="noreferrer noopener" id="discord"><BsDiscord /></a>
        </div>
      </div>
      <div className='footer__supporters'>
        <p className="supporters__label">
          Proudly supported by:
        </p>
        <div className='supporters__logos'>
          <a href="https://vicscreen.vic.gov.au/" target='_blank' rel="noreferrer noopener"><img className='vicscreen__logo' src={VicScreen} alt="VicScreen" /></a>
        </div>
      </div>
    </section>
  )
}

export default Footer