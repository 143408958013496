import DescendantPhone from "../../assets/DescendantPhone.jpeg"
import TeamTree from "../../assets/TeamTree.jpeg"

const data = [
    {
      date: "June 2023",
      title: "Let's Get Connected",
      text: <div><p>At last, Ancestor, we’ve set up our ‘socials’! Come take a Twit, a Snatch, or a Chomp with us and get insider updates and behind-the-scene-ers. But beware, Ancestor, once your descendants share the future with you, you will be compelled to act! </p>
        <br />
        <p>Check out our social links <a href="#contact" >below</a> to find out more!</p>
        <br />
        <img src={DescendantPhone} alt=""/>
      </div>
    },
    {
      date: "May 2023",
      title: "The DoE Team Grows!",
      text: <div><p>The Descendants of Earth team is growing! We are so excited for our new senior developer, 3-D character artist and 3-D structures artist to help bring DoE into the past (and onto your screens).</p>
        <br />
        <img src={TeamTree} alt=""/>
      </div>
    }, 
    {
      date: "August 2022",
      title: "New Website Goes Live",
      text: <div><p>With all of the changes we’ve been making behind the scenes, we needed to update our website to help share what’s been happening. And here we are with our flashiest version yet.</p>
      <br/>
      <p>Check out the latest screenshots taken from within the game. The future is a lot greener now, too. You’ll notice we’ve given the environment much more detail thanks to our use of the GAIA Engine in conjunction with an Australian Vegetation Pack, to give it some Aussie charm.</p>
      <br/>
      <p>Social links aren’t working yet? Good on you for trying. Those are coming next. Until then there’s always the mailing list!</p>
      </div>
    },
    {
      date: "June 2022",
      title: "First Successful iOS Port",
      text: <div><p>Who knew porting is iOS would be so hard? Certainly not us old school Apple fans. It took many, many failed attempts, but Descendants of Earth got there, friends! We have run the gauntlet, our build did not fail, and our demo is now running smoothly on iOS. Whu-chaw! Onwards and upwards.</p>
      </div>
    },
    {
      date: "April 2022",
      title: "A Demo Is Born",
      text: <div><p>Now that we have a clear idea of what our game features look like, it’s time to start building again. This latest demo represents a significant improvement on our last prototype, especially since it now features BUILDING and EXPEDITIONS. We’ve added 3D assets too, including 9 different community structures, and a freaking time pump.</p>
      <br/>
      <p>The time pump detects changes to the timeline generated by ancestor action then channels light backwards through a meteorite and by doing so moves resources forward through time. Pretty nifty. Let’s hope it doesn’t blow anyone else up, though…</p>
      <br/>
      <p>Last, and possibly least, we’ve made some huge improvements to our interface, meaning you can navigate easier, read it better, and given the thing a polish to boot. The demo remains in closed testing for now, but it brings us a big step closer to the light of day.</p>
      </div>
    },
    {
      date: "March 2022",
      title: "Tightening Up Our Design & Documentation",
      text: <div><p>What lies at the heart of Descendants of Earth? What are the bones that surround that heart? And what does the flesh look like that wraps it all up in a gooey meat sack? These were questions that needed answering. We also needed a map forward to take this venture from an idea to something we can hold in our hands.</p>
      <br/>
      <p>This was not an easy process, or a quick one, but it has helped us establish a strong foundation to begin the process of development atop. The aim was to be able to articulate the game concisely, and clearly map out the steps involved. One major breakthrough was in clarifying our core loop, which is as follows:</p>
      <ol>
        <li>1. TAKE REAL ACTION</li>
        <li>2. BUILD UP FUTURE COMMUNITIES</li>
        <li>3. LAUNCH EXPEDITIONS TO REGENERATE EARTH</li>
      </ol>
      <br/>
      <p>Rinse and repeat!</p>
      </div>
    }
  ]

export default data;