import './header.css'
import Logo from '../../assets/DOE_Logo.png'
import Swirl from '../../assets/Swirl.png'

function Header({ innerRef }) {
  return (
    <section id='home' ref={innerRef}>
        <div className="header__container">
          <img src={Swirl} className="header__swirl" alt="Descendants of Earth" />
          <div className="header__body">
            <img src={Logo} className="header__logo" alt="Descendants of Earth" />
            <h2>
              Environmental action is multiplayer.
            </h2>
          </div>
        </div>
    </section>
  )
}

export default Header