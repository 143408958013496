import React from 'react';

function FeaturesSliderBase({ activeIndex, data }) {
    return (
        <div>
            {data.map((slide, index) => (
                    
                <div 
                    key={index} 
                    className={index === activeIndex ? "devlog-slides devlog-slider-active" : "devlog-slider-inactive"}
                >
                    
                    <div className="devlog__text">
                        <div className="devlog__date">
                            <p>{slide.date}</p>
                        </div>
                        <h4>{slide.title}</h4>
                        <p>{slide.text}</p>
                    </div>
                </div>

            ))}
        </div>
    )
}

export default FeaturesSliderBase;