import React from 'react'

function FeaturesArrows({ prevSlide, nextSlide }) {
    return (
        <div className="devlog-arrows">
            <span className="devlog-prev" onClick={prevSlide}>
                &#10094;
            </span>
            <span className="devlog-next" onClick={nextSlide}>
                &#10095;
            </span>
        </div>
    )
}

export default FeaturesArrows;