import React from 'react';

function FeaturesSliderBase({ activeIndex, data }) {
    return (
        <section>
            {data.map((slide, index) => (
                
                
                <div 
                    key={index} 
                    className={index === activeIndex ? "slides slider-active" : "slider-inactive"}
                >
                    
                    <img className="slide-image" src={slide.image} alt="" />  
                    <div className="slider-text-screen"> 
                        
                        <div className="slide-text">
                            <h2>{slide.feature}</h2>
                            <p>{slide.description}</p>
                        </div>
                    </div>
                </div>

            ))}
        </section>
    )
}

export default FeaturesSliderBase;